import React, {useEffect, useState} from 'react';
import './App.css'; // This will be your custom CSS file
import joe from './images/joe.jpg'
import title from './images/cooltext453687228525124.png'
import new_gif from './images/newblast.gif'
import butterfly_gif from './images/anibutterfly2.gif'
import monitor from './images/Amonitor.gif'
import world from './images/world.gif'
import dollarspin from './images/dollarspindownd.gif'
import camel from './images/camel.gif'

function App() {
    const [pun, setPun] = useState('');

    const puns = [
        "Why did the camel start smoking? Because it wanted to be the hump day life of the party!",
        "What do you call a stylish camel who smokes? A dromedary dandy with a cigar!",
        "How does a camel light a cigarette? With its hump lighter, of course!",
        "Why did the camel refuse to quit smoking? It said, \"I can't go cold turkey, I'm a warm desert creature!",
        "What do you get when you cross a camel with a smoker? A creature who's always got the hump and a puff!",
        "BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY $CAMEL",
    ];

    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * puns.length);
        setPun(puns[randomIndex]);
    }, []);


  return (
      <div className="App">

        <header className="App-header">
            <div className="floating-container">
                <img src={dollarspin} alt="Animated GIF" className="Floating-Money"/>

                <img src={title} className="Website-Title" alt="Website Title"/>
                <img src={new_gif} className="Floating-GIF" alt="Floating GIF" />
            </div>

            <div className="scrolling-text-container">
                <div>
                    <p><marquee class={'rainbow'} scrollamount="10" behavior="scroll" direction="left"><b>{pun}</b></marquee></p>
                </div>
            </div>

        </header>
          <body>

          <div className="content-wrapper">


          <div className='main-content'>
              <div>
                  <img src={joe} className="Joe-Camel-Image" alt="Joe Camel"/>
              </div>
              <div>
                  <img src={butterfly_gif}  alt="Joe Camel"/>
              </div>

              <p> Hop in boys and dont miss out on the </p>
              <img src={camel} style={{'height': '100px'}}  alt="camel"/>
              <p> <span className="shake-and-change-color">COOLEST</span>  <b/>
              camel on the solana blockchain
              </p>

               <img src={world}  alt="twitter"/>
          </div>
          </div>

          <div style={{'padding': '75px'}}>
          <div className="links">

              <div className='main-content'>
                  <p>CA:Gem2y3adJp9q3nQSRntFnbJx6CC4zHn6tkcTBnESWKsf</p>
                  <p>Ticker: $CAMEL</p>

                  <p>important links☻!!!</p>
                  <a href="https://linktr.ee/joecamelsol"> <img src={monitor}  alt="links"/> </a>

              </div>
          </div>
          </div>
          </body>
      </div>
  );
}

export default App;

